.id-list {
  list-style: none;
  padding-left: 10px !important;
}
.id-list li {
  padding: 0.5em !important;
}
.id-list li:not(:last-child) {
  border-bottom: 1px solid white !important;
}
#cameras_list li {
  color: red;
}
#cameras_list .is_online_true {
  color: green;
}
ul.no-bullet {
  list-style-type: none;
  padding-left: 0px;
}
.profile-img {
  max-width: 200px;
  max-height: 200px;
}
ul.errorlist > li {
  color: red;
}
label:not(.required) {
  font-weight: normal;
}
label.required {
  font-weight: bold;
}
label.required::after {
  content: " *";
  color: red;
}
select {
  max-width: 50em;
}
